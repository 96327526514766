<template>
  <div :class="$style.footer">
    <div :class="$style.footerInner">
      <div class="row">
        <div class="col">
          <div class="d-flex flex-column" :class="$style.leftFooter">
            <router-link to="/layanan-pelanggan" :style="{ color: color }"
              >Layanan Pelanggan Akses Toko<RightCircleOutlined class="ml-1"
            /></router-link>
            <router-link to="/faq" :style="{ color: color }"
              >Faq<RightCircleOutlined class="ml-1"
            /></router-link>
            <router-link
              to=""
              @click="
                () => {
                  fetchData()
                  modal1Visible = true
                  formState.title_state = 'Version Rilis'
                }
              "
            >
              <!-- <small :style="{ color: color }">Version: {{ $store.getters.appVersion }}</small> -->
              <small :style="{ color: color }">Version: {{ data.map(v => v.version)[0] }}</small>
              <RightCircleOutlined class="ml-1" />
            </router-link>
          </div>
        </div>
        <div class="col" :style="{ color: color }">
          <div class="d-flex flex-row justify-content-end flex-grow">
            <div class="d-flex flex-column text-right" :class="$style.rightFooter">
              <span :class="$style.company"
                >{{ new Date().getFullYear() }} PT Sinergi Informatika Semen Indonesia,</span
              >
              <span>anak usaha dari PT Semen Indonesia TBK.</span>
              <span>All rights reserved.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a-modal
    v-model:visible="modal1Visible"
    :title="formState.title_state"
    width="700px"
    :destroy-on-close="true"
    :mask-closable="false"
    @ok="modal1Visible = false"
  >
    <template #footer>
      <a-button type="default" @click="modal1Visible = false">Close</a-button>
    </template>
    <div v-if="data.length < 1">
      <div class="ant-empty ant-empty-normal">
        <div class="ant-empty-image">
          <svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41">
            <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
              <ellipse
                class="ant-empty-img-simple-ellipse"
                fill="#F5F5F5"
                cx="32"
                cy="33"
                rx="32"
                ry="7"
              ></ellipse>
              <g class="ant-empty-img-simple-g" fill-rule="nonzero" stroke="#D9D9D9">
                <path
                  d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"
                ></path>
                <path
                  d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                  fill="#FAFAFA"
                  class="ant-empty-img-simple-path"
                ></path>
              </g>
            </g>
          </svg>
        </div>
        <p class="ant-empty-description">No Data</p>
      </div>
    </div>
    <div v-else>
      <div v-for="item in data" :key="item.id">
        <span class="font-weight-bold" v-html="'Version ' + item.version + ' '"></span>
        <span v-html="'( ' + item.date_rilis + ' )'"></span>
        <br />
        <div class="mb-2 mt-2 ml-3">
          <span v-html="item.deskripsi"></span>
        </div>
      </div>
    </div>
  </a-modal>

  <a-modal
    v-model:visible="modalVisilePassword"
    :title="`Reset Password`"
    width="550px"
    :close-icon="false"
    :mask-closable="false"
    :closable="false"
    @ok="modalVisilePassword = false"
  >
    <template #footer>
      <div v-if="stateReset.resetPassword">
        <a-button type="danger" @click="resetPassword" :loading="loading">Submit</a-button>
      </div>
      <div v-if="stateReset.finish">
        <a-button @click="logout()" type="danger">
          MASUK
        </a-button>
      </div>
    </template>

    <div v-if="stateReset.resetPassword">
      <a-tooltip>
        <div class="ml-5 mr-5 mb-5 text-center">
          <strong>Silahkan melakukan penggantian password secara berkala demi meningkatkan keamanan akun anda.</strong>
        </div>
      </a-tooltip>
      <a-form
        class="myform"
        layout="vertical"
        :model="loginFormReset"
        :rules="rulesReset"
        ref="formOtpReset"
      >
        <a-form-item class="mb-0" label="Kata sandi lama">
          <a-input-password
            v-model:value="loginFormReset.old_password"
            placeholder=""
            @input="validateOldPass = false"
          />
          <span class="text-danger" v-show="validateOldPass">{{ validateOldPassMsg }}</span>
        </a-form-item>
        <a-form-item class="mb-0" label="Kata sandi baru">
          <a-input-password
            v-model:value="loginFormReset.password"
            placeholder=""
            autocomplete="new-password"
            @input="validatePass = false"
          />
          <span class="text-danger" v-show="validatePass">{{ validatePassMsg }}</span>
        </a-form-item>
        <a-form-item class="mb-0" label="Konfirmasi kata sandi baru">
          <a-input-password
            v-model:value="loginFormReset.retype_password"
            placeholder=""
            autocomplete="new-password"
            @input="validateConfirmPass = false"
          />
          <span class="text-danger" v-show="validateConfirmPass">{{ validateConfirmPassMsg }}</span>
        </a-form-item>
      </a-form>
    </div>

    <div v-if="stateReset.finish">
      <p>Reset Kata Sandi Baru berhasil dilakukan</p>
    </div>
  </a-modal>
</template>

<script>
import { defineComponent, ref, watch, onMounted, reactive, toRefs } from 'vue'
import apiClient from '@/services/axios'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { RightCircleOutlined } from '@ant-design/icons-vue'
import store from 'store'
import { message } from 'ant-design-vue'

const accessStatuses = store.get('status')
const profile = store.get('profile')

export default defineComponent({
  name: 'MFilter',
  components: {
    RightCircleOutlined,
  },
  props: {
    color: {
      type: String,
      default: null,
    },
  },

  setup() {
    const store = useStore()
    const emailIdentity = ''
    const settings = computed(() => store.getters.settings)
    const modal1Visible = ref(false)
    const modalVisilePassword = ref(false)
    const formState = ref({})
    const data = ref([])
    const params = ref({})
    const validateOldPass = ref(false)
    const validateOldPassMsg = ref(null)
    const validatePass = ref(false)
    const validatePassMsg = ref(null)
    const validateConfirmPass = ref(false)
    const validateConfirmPassMsg = ref(null)

    const fetchData = () => {
      const _params = {}
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/version/get', {
          params: _params,
        })
        .then(response => {
          const { items } = response.data
          console.log('accessStatuses', accessStatuses)
          data.value = items
          if (accessStatuses && accessStatuses.reset_status === true) {
            modalVisilePassword.value = true
          } else {
            modalVisilePassword.value = false
          }
        })
        .finally(() => {
          state.isFetching = false
        })
    }
    const formOtpReset = ref()
    const loadingReset = ref(false)
    const stateReset = reactive({
      requestOtp: true,
      resetPassword: true,
      finish: false,
    })
    const rulesReset = {
      identity: [
        {
          required: true,
          message: 'Please input your username or email!',
          trigger: 'change',
        },
      ],
      password: [{ required: true, message: 'Please input password!', trigger: 'change' }],
    }
    const loginFormReset = reactive({
      old_password: null,
      password: null,
      retype_password: null,
      identity: profile && profile.email,
    })
    const requestOtpReset = () => {
      formOtpReset.value.validate().then(() => {
        loadingReset.value = true
        apiClient
          .post('/api/auth/otp-request', loginFormReset)
          .then(response => {
            console.log(response)
          })
          .finally(() => {
            loadingReset.value = false
            stateReset.requestOtp = false
            stateReset.resetPassword = true
            message.success('Permintaan OTP berhasil dikirim')
          })
      })
    }
    const showResetPassword = computed(() => {
      return !stateReset.requestOtp && loginFormReset.identity && stateReset.resetPassword
    })
    const resetPassword = () => {
      // apiClient.post('/api/auth/reset-password', loginForm)
      loadingReset.value = true
      apiClient
        .post('/api/auth/v2/reset-password', loginFormReset)
        .then(response => {
          console.log(response)
          stateReset.requestOtp = false
          stateReset.resetPassword = false
          stateReset.finish = true
          loginFormReset.identity = null
          loginFormReset.old_password = null
          loginFormReset.password = null
          loginFormReset.retype_password = null
          message.success('Reset Password berhasil dilakukan')
        })
        .catch(error => {
          if (error.response) {
            let responseError = error.response.data
            responseError.forEach(e => {
              if (e.field === 'old_password') {
                let msg = e.message
                validateOldPass.value = true
                validateOldPassMsg.value = msg ? msg : null
                message.error(e.message)
              }
              if (e.field === 'password') {
                let msg = e.message
                validatePass.value = true
                validatePassMsg.value = msg ? msg : null
                message.error(e.message)
              }
              if (e.field === 'retype_password') {
                let msg = e.message
                validateConfirmPass.value = true
                validateConfirmPassMsg.value = msg ? msg : null
                message.error(e.message)
              }
            });
          }
        })
        .finally(() => {
          loadingReset.value = false
        })
    }
    const logout = () => {
      store.dispatch('user/LOGOUT')
    }
    const resetForm = () => {
      stateReset.requestOtp = true
      stateReset.resetPassword = false
      stateReset.finish = false
      loginFormReset.identity = null
      loginFormReset.old_password = null
      loginFormReset.password = null
      loginFormReset.retype_password = null
    }

    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
    })

    onMounted(() => {
      fetchData()
    })

    const visible = ref(false)

    watch(modal1Visible, (newValue, oldValue) => {
      if (newValue === false) {
        formState.value = {}
      }
    })

    return {
      formOtpReset,
      stateReset,
      rulesReset,
      logout,
      loginFormReset,
      requestOtpReset,
      resetForm,
      showResetPassword,
      resetPassword,
      loadingReset,
      settings,
      modalVisilePassword,
      modal1Visible,
      visible,
      formState,
      data,
      state,
      emailIdentity,
      accessStatuses,
      ...toRefs(state),
      fetchData,
      validateOldPass,
      validateOldPassMsg,
      validatePass,
      validatePassMsg,
      validateConfirmPass,
      validateConfirmPassMsg,
    }
  },
})
</script>

<style lang="scss" module>
@import '@/assets/scss/main/footer.scss';
$xs-min-width: 395px;
$xs-max-width: 394px;

.footer {
  line-height: rem(22);
}

p {
  margin: 0;
}

.footer a:hover {
  text-decoration: underline;
}

.company {
  white-space: nowrap;
}

@media (max-width: $md-max-width) {
  .leftFooter {
    font-size: rem(14);
  }
  .rightFooter {
    font-size: rem(14);
  }
}

@media (max-width: $sm-max-width) {
  .leftFooter {
    font-size: rem(14);
  }
  .rightFooter {
    font-size: rem(12);
  }
}

@media (max-width: $xs-max-width) {
  .leftFooter {
    font-size: rem(13);
  }
  .rightFooter {
    font-size: rem(12);
  }
}

.footer {
  @media (max-width: $md-max-width) {
    padding: rem(14) !important;
  }
  @media (max-width: $sm-max-width) {
    padding: rem(10) !important;
  }
  @media (max-width: $xs-max-width) {
    padding: 0 rem(8) rem(8) rem(8) !important;
    line-height: rem(18);
  }
}
</style>
