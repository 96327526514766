<template>
    <a-layout>
        <a-layout-content>
            <div class="page-layout">
                <nav class="nav">
                    <div class="container nav-mdxl">
                        <RouterLink to="/guest/welcome" class="logo-mdxl">
                            <img src="@/assets/images/landing-page/logo_mdxl.png" alt="logo mdxl" />
                        </RouterLink>
                    </div>
                </nav>
                <div class="container my-4">
                    <router-view v-slot="{ Component }">
                        <transition :name="settings.routerAnimation" mode="out-in">
                            <component :is="Component" />
                        </transition>
                    </router-view>
                </div>
            </div>
        </a-layout-content>
    </a-layout>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'PageLayout',
    components: {
        // 
    },
    setup() {
        const store = useStore()
        const settings = computed(() => store.getters.settings)
        return { settings }
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/layouts/page.scss';
</style>
