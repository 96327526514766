<template>
  <div :class="$style.breadcrumbs">
    <div :class="$style.path">
      <router-link to="/">Home</router-link>
      <template v-for="(item, index) in breadcrumb">
        <span v-if="index != 0" :key="index">
          <span :class="$style.arrow"></span>
          <span>{{ item.title }}</span>
        </span>
      </template>
      <span v-if="activeItem">
        <span :class="$style.arrow"></span>
        <strong :class="$style.current">{{ activeItem.title }}</strong> 
        <router-link 
          to=""
          @click="
            () => {
              href = '/'+$route.path.substring(1)
              fetchData(href)
              modal1Visible = true
              formState.title_state = activeItem.title ?? 'undefined'
            }
          ">
          <i class="fa fa-question-circle pull-right" title="Note" aria-hidden="true"></i>
        </router-link>
      </span>
    </div>
  </div>
  <a-modal
    v-model:visible="modal1Visible"
    :title="formState.title_state"
    width="1000px"
    :destroy-on-close="true"
    :mask-closable="false"
    @ok="modal1Visible = false">
    <template #footer>
      <a-button
        type="default"
        @click="modal1Visible = false"
      >Close</a-button>
    </template>
    <div v-for="item in data" :key="item.id">
      <div class="mb-2" v-if="item.note == null">
        <div class="ant-empty ant-empty-normal">
          <div class="ant-empty-image">
            <svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41">
            <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
              <ellipse class="ant-empty-img-simple-ellipse" fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
              <g class="ant-empty-img-simple-g" fill-rule="nonzero" stroke="#D9D9D9">
                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA" class="ant-empty-img-simple-path"></path>
              </g>
            </g>
            </svg>
          </div>
          <p class="ant-empty-description">No Data</p>
        </div>
      </div>
      <div v-else>
        <span :key="item.id" v-html="item.note"></span>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { ref, watch, onMounted, reactive, computed } from 'vue'
import apiClient from '@/services/axios'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getMenuData } from '@/services/menu'
import reduce from 'lodash/reduce'
import { Modal, message } from 'ant-design-vue'

export default {
  name: 'Breadcrumbs',
  setup() {
    const route = useRoute()
    const store = useStore()
    const activeItem = ref([])
    const routePath = computed(() => route.path)
    const formState = ref({})
    const loading = ref(false)
    const data = ref([])
    const modal1Visible = ref(false)
    const href = ''
    const getPath = (data, url, parents = []) => {
      if (url === '/') {
        url = '/dashboard'
      }
      const items = reduce(
        data,
        (result, entry) => {
          if (result.length) {
            return result
          }
          if (entry.children) {
            const nested = getPath(entry.children, url, [entry].concat(parents))
            return (result || []).concat(nested.filter(e => !!e))
          }
          if (entry.url === url) {
            route.meta.title_db = entry.title
            return [entry].concat(parents)
          }
          return result
        },
        [],
      )
      if (items.length === 0 && parents.length === 0) {
        items.push({ title: route.meta.title })
      }
      activeItem.value = items[0]
      return items
    }

    const fetchData = val => {
      state.isFetching = true
      const form_data = { href: val };
      apiClient
        .post('/api/menus/note', form_data)
        .then(response => {
          const { items } = response.data
          data.value = items
        })
        .catch(e => message.error('Gagal load data!'))
    }

    const state = reactive({
      loading: false,
      isFetching: false,
    })

    watch(modal1Visible, (newValue, oldValue) => {
      if (newValue === false) {
        formState.value = {}
      }
    })

    return {
      breadcrumb: computed(() => getPath(store.getters['user/menu'], routePath.value)),
      activeItem,
      formState,
      loading,
      data,
      modal1Visible,
      href,
      fetchData,
    }
  },
}
</script>

<style lang="scss" module>
@import '@/assets/scss/main/breadcrumbs.scss';
</style>
