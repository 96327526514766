export const routerMenus = [
  {
    path: '/akun',
    meta: { title: 'Akun' },
    component: () => import('@/views/akun/index.vue'),
  },
  {
    path: '/parameter',
    meta: { title: 'Parameter' },
    component: () => import('@/views/parameter/index.vue'),
  },
  {
    path: '/parameter/lokasi-area',
    meta: { title: 'Lokasi Area' },
    component: () => import('@/views/parameter/lokasi-area/index.vue'),
  },
  {
    path: '/parameter/metode-pembayaran',
    meta: { title: 'Metode Pembayaran' },
    component: () => import('@/views/parameter/metode-pembayaran/index.vue'),
  },
  {
    path: '/parameter/layanan-pelanggan',
    meta: { title: 'Layanan Pelanggan' },
    component: () => import('@/views/parameter/layanan-pelanggan/index.vue'),
  },
  {
    path: '/parameter/tanggal-mundur-penjualan',
    meta: { title: 'Tanggal Mundur Penjualan' },
    component: () => import('@/views/parameter/tanggal-mundur-penjualan/index.vue'),
  },
  {
    path: '/parameter/parameter-produk-satuan-penjualan',
    meta: { title: 'Parameter Produk Satuan Penjualan' },
    component: () => import('@/views/parameter/parameter-produk-satuan-penjualan/index.vue'),
  },
  {
    path: '/parameter/parameter-produk-satuan-pembelian',
    meta: { title: 'Parameter Produk Satuan Pembelian' },
    component: () => import('@/views/parameter/parameter-produk-satuan-pembelian/index.vue'),
  },
  {
    path: '/parameter/parameter-produk-kategori',
    meta: { title: 'Parameter Produk Kategori' },
    component: () => import('@/views/parameter/parameter-produk-kategori/index.vue'),
  },
  {
    path: '/parameter/parameter-produk-merk',
    meta: { title: 'Parameter Produk Merk' },
    component: () => import('@/views/parameter/parameter-produk-merk/index.vue'),
  },
  {
    path: '/parameter/faq',
    meta: { title: 'FAQ' },
    component: () => import('@/views/parameter/faq/index.vue'),
  },
  {
    path: '/parameter/syarat-dan-ketentuan',
    meta: { title: 'Syarat dan Ketentuan' },
    component: () => import('@/views/parameter/syarat-dan-ketentuan/index.vue'),
  },
  {
    path: '/parameter/parameter-produk',
    meta: { title: 'Parameter Produk' },
    component: () => import('@/views/parameter/parameter-produk-kategori/index.vue'),
  },
  {
    path: '/parameter/pemetaan-regional-area',
    meta: { title: 'Pemetaan Regional Area' },
    component: () => import('@/views/parameter/pemetaan-regional-area/index.vue'),
  },
  {
    path: '/parameter/pemetaan-grup-area',
    meta: { title: 'Pemetaan Grup Area' },
    component: () => import('@/views/parameter/pemetaan-grup-area/index.vue'),
  },
  {
    path: '/parameter/pemetaan-organisasi',
    meta: { title: 'Pemetaan Organisasi' },
    component: () => import('@/views/parameter/pemetaan-organisasi/index.vue'),
  },
  {
    path: '/parameter/konversi-uom',
    meta: { title: 'Konversi UoM' },
    component: () => import('@/views/parameter/konversi-uom/index.vue'),
  },
  {
    path: '/parameter/pemetaan-wilayah',
    meta: { title: 'Pemetaan Wilayah' },
    component: () => import('@/views/parameter/pemetaan-wilayah/index.vue'),
  },
  {
    path: '/parameter/satuan',
    meta: { title: 'Satuan' },
    component: () => import('@/views/parameter/satuan/index.vue'),
  },
  {
    path: '/parameter/konversi-satuan',
    meta: { title: 'Konversi Satuan' },
    component: () => import('@/views/parameter/konversi-satuan/index.vue'),
  },
  {
    path: '/parameter/kontrak-volume',
    meta: { title: 'Kontrak Volume' },
    component: () => import('@/views/parameter/kontrak-volume/index.vue'),
  },
  {
    path: '/parameter/parameter-produk-tipe',
    meta: { title: 'Parameter Produk Tipe' },
    component: () => import('@/views/parameter/parameter-produk-tipe/index.vue'),
  },
  {
    path: '/parameter/parameter-produk-sub-kategori',
    meta: { title: 'Parameter Produk Sub Kategori' },
    component: () => import('@/views/parameter/parameter-produk-sub-kategori/index.vue'),
  },
  {
    path: '/parameter/parameter-produk-supplier',
    meta: { title: 'Parameter Produk Supplier' },
    component: () => import('@/views/parameter/parameter-produk-supplier/index.vue'),
  },
  {
    path: '/manajemen-user',
    meta: { title: 'Manajemen User' },
    component: () => import('@/views/manajemen-user/index.vue'),
  },
  {
    path: '/manajemen-user/daftar-pengguna',
    meta: { title: 'Daftar Pengguna' },
    component: () => import('@/views/manajemen-user/daftar-pengguna/index.vue'),
  },
  {
    path: '/manajemen-user/kelola-pengguna',
    meta: { title: 'Kelola Pengguna' },
    component: () => import('@/views/manajemen-user/kelola-pengguna/index.vue'),
  },
  {
    path: '/manajemen-user/peran-pengguna',
    meta: { title: 'Peran Pengguna' },
    component: () => import('@/views/manajemen-user/peran-pengguna/index.vue'),
  },
  {
      name: 'lihat-role',
    path: '/lihat-daftar-role',
    meta: { title: 'Lihat Role' },
    component: () => import('@/views/manajemen-user/lihat-daftar-role/index.vue'),
  },
  {
    path: '/manajemen-user/sales-organisasi',
    meta: { title: 'Sales Organisasi' },
    component: () => import('@/views/manajemen-user/sales-organisasi/index.vue'),
  },
  {
    path: '/master',
    meta: { title: 'Master' },
    component: () => import('@/views/master/index.vue'),
  },
  {
    path: '/master/pengajuan-toko',
    meta: { title: 'Pengajuan Toko' },
    component: () => import('@/views/master/pengajuan-toko/index.vue'),
  },
  {
    path: '/master/data-toko',
    meta: { title: 'Master Data Toko' },
    component: () => import('@/views/master/kelola-toko/index.vue'),
  },
  {
    path: '/master/tambah-produk',
    meta: { title: 'Tambah Produk' },
    component: () => import('@/views/master/tambah-produk/index.vue'),
  },
  {
    path: '/master/produk',
    meta: { title: 'Master Produk' },
    component: () => import('@/views/master/kelola-produk/index.vue'),
  },
  {
    path: '/master/bank',
    meta: { title: 'Bank' },
    component: () => import('@/views/master/bank/index.vue'),
  },
  {
    path: '/master/segmen-pelanggan',
    meta: { title: 'Segmen Pelanggan' },
    component: () => import('@/views/master/segmen-pelanggan/index.vue'),
  },
  {
    path: '/master/unggah-master-toko',
    meta: { title: 'Unggah Master Toko' },
    component: () => import('@/views/master/unggah-master-toko/index.vue'),
  },
  {
    path: '/master/distributor',
    meta: { title: 'Master Distributor' },
    component: () => import('@/views/master/kelola-distributor/index.vue'),
  },
  {
    path: '/master/gudang-distributor',
    meta: { title: 'Master Gudang Distributor' },
    component: () => import('@/views/master/kelola-gudang/index.vue'),
  },
  {
    path: '/master/master-data-jaringan-lt',
    meta: { title: 'Master Data Jaringan LT' },
    component: () => import('@/views/master/master-data-jaringan-lt/index.vue'),
  },
  {
    path: '/master/master-data-lt',
    meta: { title: 'Master Data LT' },
    component: () => import('@/views/master/master-data-lt/index.vue'),
  },
  {
    path: '/master/master-api-distributor',
    meta: { title: 'Master API Distributor' },
    component: () => import('@/views/master/master-api-distributor/index.vue'),
  },
  {
    path: '/master/tambah-packing-plant',
    meta: { title: 'Tambah Packing Plant' },
    component: () => import('@/views/master/tambah-packing-plant/index.vue'),
  },
  {
    path: '/master/kelola-packing-plant',
    meta: { title: 'Kelola Packing Plant' },
    component: () => import('@/views/master/kelola-packing-plant/index.vue'),
  },
  {
    path: '/master/pengajuan-gudang',
    meta: { title: 'Pengajuan Gudang' },
    component: () => import('@/views/master/pengajuan-gudang/index.vue'),
  },
  {
    path: '/master/log-api',
    meta: { title: 'Log Api' },
    component: () => import('@/views/master/log-api/index.vue'),
  },
  {
    path: '/master/mapping-dist',
    meta: { title: 'Mapping Distributor By Product' },
    component: () => import('@/views/master/mapping-dist/index.vue'),
  },
  {
    path: '/laporan/log-data-msc',
    meta: { title: 'Log Data MSC' },
    component: () => import('@/views/laporan/log-data-msc/index.vue'),
  },
  {
    path: '/master/master-toko-v2',
    meta: { title: 'Master Data Toko' },
    component: () => import('@/views/master/master-toko-v2/index.vue'),
  },
  {
    path: '/master/unggah-mapping-distributor',
    meta: { title: 'Unggah Mapping Distributor' },
    component: () => import('@/views/master/unggah-mapping-distributor/index.vue'),
  },
  {
    path: '/audit-trail',
    meta: { title: 'Audit Trail' },
    component: () => import('@/views/audit-trail/index.vue'),
  },
  {
    path: '/gudang',
    meta: { title: 'Gudang' },
    component: () => import('@/views/gudang/index.vue'),
  },
  {
    path: '/override',
    meta: { title: 'Override' },
    component: () => import('@/views/override/index.vue'),
  },
  {
    path: '/master/master-data-wilayah',
    meta: { title: 'Master Data Wilayah' },
    component: () => import('@/views/master/kelola-wilayah/index.vue'),
  },
]
