import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
// check
import App from './App.vue'
//import './registerServiceWorker'
import router from './router'
import store from './store'
import { i18n } from './localization.vue'

import MdTable from '@/components/Main/MdTable.vue'
import './assets/scss/global.scss'

import RegisterDirective from './directives'
import { QuillEditor } from '@vueup/vue-quill'

import VueGoogleMaps from '@fawmi/vue-google-maps'

const app = createApp(App)
app.component('MdTable', MdTable)
app.component('QuillEditor', QuillEditor)
RegisterDirective(app)
app
  .use(store)
  .use(router)
  .use(i18n)
  .use(Antd)
  .use(PerfectScrollbar)
  .use(VueGoogleMaps, {
    load: {
      key: '',
      libraries: "places",
    },
  })
  .mount('#app')
