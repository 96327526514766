export const routerProgramLoyalty = [
  {
    path: '/program-loyalty',
    meta: { title: 'Master Program' },
    component: () => import('@/views/program-loyalty/program/index.vue'),
  },
  {
    path: '/program-loyalty/:id/list-toko',
    meta: { title: 'Program Toko' },
    component: () => import('@/views/program-loyalty/list-toko/index.vue'),
  },
  {
    path: '/program-loyalty/:id/assign-toko',
    meta: { title: 'Program Toko' },
    component: () => import('@/views/program-loyalty/assign-toko-program/index.vue'),
  },
  {
    path: '/program-loyalty/uom',
    meta: { title: 'Master UOM' },
    component: () => import('@/views/program-loyalty/uom/index.vue'),
  },
  {
    path: '/program-loyalty/reward',
    meta: { title: 'Master Rewards' },
    component: () => import('@/views/program-loyalty/reward/index.vue'),
  },
  {
    path: '/program-loyalty/laporan/penukaran-hadiah',
    meta: { title: 'Laporan Penukaran Hadiah' },
    component: () => import('@/views/program-loyalty/laporan-penukaran-hadiah/index.vue'),
  },
  {
    path: '/program-loyalty/laporan/poin-program-apresiasi',
    meta: { title: 'Laporan Poin Program Apresiasi Toko' },
    component: () => import('@/views/program-loyalty/laporan-program-apresiasi/index.vue'),
  },
  {
    path: '/program-loyalty/poin-histories',
    meta: { title: 'Laporan Riwayat Poin Program' },
    component: () => import('@/views/program-loyalty/poin-histories/index.vue'),
  },
  {
    path: '/program-loyalty/laporan/registrasi-program',
    meta: { title: 'Laporan Registrasi Program Apresiasi Toko' },
    component: () => import('@/views/program-loyalty/laporan-registrasi-program/index.vue'),
  },
  {
    path: '/program-loyalty/laporan/penukaran-uang',
    meta: { title: 'Laporan Penukaran Uang ' },
    component: () => import('@/views/program-loyalty/laporan-penukaran-uang/index.vue'),
  },
  {
    path: '/program-loyalty/target-volume-dan-pencapaian-program-loyalti',
    meta: { title: 'Target Volume dan Pencapaian Program Loyalti' },
    component: () => import('@/views/program-loyalty/target-volume-program-loyalti/index.vue'),
  },
  {
    path: '/program-loyalty/unggah-point-toko',
    meta: { title: 'Unggah Point Toko' },
    component: () => import('@/views/program-loyalty/unggah-point-toko/index.vue'),
  },
  {
    path: '/program-loyalty/unggah-toko-whitelist',
    meta: { title: 'Unggah Toko Whitelist' },
    component: () => import('@/views/program-loyalty/unggah-toko-register-program/index.vue'),
  },
  {
    path: '/program-loyalty/unggah-registrasi-toko',
    meta: { title: 'Unggah Registrasi Toko' },
    component: () => import('@/views/program-loyalty/unggah-registrasi-toko/index.vue'),
  },
  {
    path: '/program-loyalty/unggah-target-volume',
    meta: { title: 'Unggah Target Volume' },
    component: () => import('@/views/program-loyalty/unggah-target-volume/index.vue'),
  },
  {
    path: '/program-loyalty/points/approval',
    meta: { title: 'Approval Poin' },
    component: () => import('@/views/program-loyalty/points/approval.vue'),
  },
  {
    path: '/program-loyalty/points/monitoring-progress',
    meta: { title: 'Monitoring Progress Poin' },
    component: () => import('@/views/program-loyalty/points/monitoringProgress.vue'),
  },
  {
    path: '/program-loyalty/points/history-approval',
    meta: { title: 'History Approval Poin' },
    component: () => import('@/views/program-loyalty/points/historyApproval.vue'),
  },
  {
    path: '/program-loyalty/list-toko',
    meta: { title: 'List Toko' },
    component: () => import('@/views/program-loyalty/list-toko/index.vue'),
  },
  {
    path: '/program-loyalty/points/transfer',
    meta: { title: 'Transfer Poin' },
    component: () => import('@/views/program-loyalty/points/transfer.vue'),
  },
  {
    path: '/program-loyalty/approval-redeem',
    meta: { title: 'Approval Redeem' },
    component: () => import('@/views/program-loyalty/redeem/approval.vue'),
  },
  {
    path: '/program-loyalty/points-v2/history-approval',
    meta: { title: 'History Approval Poin V2' },
    component: () => import('@/views/program-loyalty/points/historyApprovalV2.vue'),
  },
  {
    path: '/program-loyalty/points-v2/monitoring-progress',
    meta: { title: 'Monitoring Progress Poin V2' },
    component: () => import('@/views/program-loyalty/points/monitoringProgressV2.vue'),
  },
  {
    path: '/program-loyalty/points-v2/approval',
    meta: { title: 'Approval Poin V2' },
    component: () => import('@/views/program-loyalty/points/approvalV2.vue'),
  },
  {
    path: '/program-loyalty/points-v2/transfer',
    meta: { title: 'Transfer Poin V2' },
    component: () => import('@/views/program-loyalty/points/transferV2.vue'),
  },
]
