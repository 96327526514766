export const routerConfiguration = [{
        path: '/konfigurasi-system',
        meta: { title: 'Konfigurasi System' },
        component: () => import('@/views/konfigurasi-system/index.vue'),
    },
    {
        path: '/konfigurasi-system/konfigurasi-embedded-metabase',
        meta: { title: 'Konfigurasi Embedded Metabase' },
        component: () => import('@/views/konfigurasi-system/konfigurasi-embedded-metabase/index.vue'),
    },
    {
        path: '/konfigurasi-system/konfigurasi-menu',
        component: () => import('@/views/konfigurasi-system/konfigurasi-menu/index.vue'),
    },
    {
        path: '/konfigurasi-system/konfigurasi-manual-book',
        meta: { title: 'Konfigurasi Manual Book' },
        component: () => import('@/views/konfigurasi-system/konfigurasi-manual-book/index.vue'),
    },
    {
        path: '/konfigurasi-system/konfigurasi-synchron',
        meta: { title: 'Konfigurasi Synchron' },
        component: () => import('@/views/konfigurasi-system/konfigurasi-synchron/index.vue'),
    },
    {
        path: '/konfigurasi-system/version-rilis',
        meta: { title: 'Version Rilis' },
        component: () => import('@/views/konfigurasi-system/version-rilis/index.vue'),
    },
]