import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'
import apiClient from '@/services/axios'
import DeviceDetector from "device-detector-js"
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'

// Layouts
import AuthLayout from '@/layouts/AuthLayout.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import GuestLayout from '@/layouts/GuestLayout.vue'
import PageLayout from '@/layouts/PageLayout.vue'

// Helpers
import { ROLE_KAM, ROLE_CORSALES, hasRoles } from '@/helpers'

// Router Modules
import { routerMenus } from './router-menu'
import { routerLaporan } from './router-laporan'
import { routerGroups } from './router-group'
import { routerMetabase } from './router-metabase'
import { routerManualBook } from './router-manual-book'
import { routerConfiguration } from './router-configuration'
import { routerRestApi } from './router-rest-api'
import { routerPromoManagement } from './router-promo-management'
import { routerProgramLoyalty } from './router-program-loyalty'

// Konfigurasi Router
const router = createRouter({
    base: process.env.BASE_URL,
    history: createWebHashHistory(),
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes: [
        {
            path: '/',
            name: 'home',
            redirect: '/dashboard',
            component: MainLayout,
            meta: { authRequired: true, hidden: true },
            children: [
                { path: '/dashboard', meta: { title: 'Dashboard', isLogo: false }, component: () => import('@/views/dashboard/index.vue') },
                { path: '/errors/403', name: 'error403', meta: { title: 'Error 403' }, component: () => import('@/views/errors/403.vue') },
                { path: '/errors/404', name: 'error404', meta: { title: 'Error 404' }, component: () => import('@/views/errors/404.vue') },
                { path: '/errors/500', name: 'error500', meta: { title: 'Error 500' }, component: () => import('@/views/errors/500.vue') },

                // Menggunakan spread untuk menghindari duplikasi kode
                ...routerMenus,
                ...routerLaporan,
                ...routerConfiguration,
                ...routerRestApi,
                ...routerMetabase,
                ...routerManualBook,
                ...routerPromoManagement,
                ...routerProgramLoyalty,
                ...routerGroups,
            ],
        },
        {
            path: '/guest',
            name: 'guest',
            redirect: '/guest/welcome',
            component: GuestLayout,
            meta: { authRequired: false, hidden: true },
            children: [
                { path: 'welcome', meta: { title: 'Welcome' }, component: () => import('@/views/welcome/index.vue') },
            ],
        },
        {
            path: '/auth',
            component: AuthLayout,
            redirect: 'auth/login',
            children: [
                { path: '/auth/login', name: 'login', meta: { title: 'Sign In' }, redirect: '/guest/welcome' },
                { path: '/auth/register', meta: { title: 'Sign Up' }, component: () => import('@/views/auth/register/index.vue') },
                { path: '/auth/forgot-password', meta: { title: 'Forgot Password' }, component: () => import('@/views/auth/forgot-password/index.vue') },
                { path: '/auth/lockscreen', meta: { title: 'Lockscreen' }, component: () => import('@/views/auth/lockscreen/index.vue') },
            ],
        },
        {
            path: '/page',
            name: 'page',
            component: PageLayout,
            children: [
                { path: '/page/faq', meta: { title: 'Faq' }, component: () => import('@/views/page/faq.vue') },
                { path: '/page/:slug', component: () => import('@/views/page/index.vue') },
            ],
        },
        {
            path: '/layanan-pelanggan',
            name: 'layanan-pelanggan',
            component: MainLayout,
            meta: { authRequired: true, hidden: true, title: 'Layanan Pelanggan' },
            children: [
                { path: 'layanan-pelanggan', meta: { title: 'Layanan Pelanggan' }, component: () => import('@/views/layanan-pelanggan/index.vue') },
            ],
        },
        // Redirect semua path yang tidak dikenali ke halaman 404
        { path: '/:pathMatch(.*)*', redirect: { name: 'error404' } },
    ],
})

// Middleware Before Route Change
router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.authRequired)) {
        try {
            const data = await store.dispatch('user/LOAD_CURRENT_ACCOUNT', to.path)

            if (!includes(data.links, to.fullPath) && !includes(['error403', 'error404', 'error500'], to.name) && isEmpty(to.params) && isEmpty(to.query)) {
                return next({ path: '/errors/403' })
            }

            // Redirect berdasarkan role
            if (to.fullPath === '/dashboard' && hasRoles([ROLE_KAM, ROLE_CORSALES])) {
                return next({ path: '/atbusiness/dashboard' })
            }
            if (to.fullPath === '/guest/welcome') {
                return next({ name: 'home' })
            }

            next()
        } catch (error) {
            console.error('router.js: Error loading user', error)
            next({ path: '/guest/welcome' })
        }
    } else {
        next()
    }
})

// Middleware After Route Change
router.afterEach((to) => {
    const deviceDetector = new DeviceDetector()
    const device = deviceDetector.parse(navigator.userAgent)

    apiClient.post('/api/log-web-access', {
        device: `${device.os.name}, ${device.device.brand}`,
        ip_address: '',
        location: '',
        route: to.fullPath,
        menu: to.meta.title,
    })
})

// Handle Error saat Loading Chunk Gagal
router.onError(error => {
    if (/loading chunk \d* failed./i.test(error.message)) {
        console.warn('Chunk loading failed, reloading page...', error)
        window.location.reload()
    }
})

export default router
